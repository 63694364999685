import "@fontsource-variable/dosis";
import { loadLocale } from "./locales/load.js";

async function loadDesktop() {
	document.querySelector(".game").remove();
	document.querySelector(".notice.landscape").remove();

	const { default: QRCode } = await import("qrcode");
	/** @type {HTMLImageElement} */
	const container = document.querySelector(".notice.desktop .qrcode");

	QRCode.toString(
		document.URL,
		{
			margin: 1,
			color: { dark: "#242c32", light: "#00000000" },
		},
		(error, string) => {
			if (error) {
				return;
			}

			container.innerHTML = string;
		},
	);
}

async function loadMobile() {
	window.initialized = true;
	document.querySelector(".notice.desktop").remove();

	const { default: intro } = await import("./intro.js");

	await intro.run();
	intro.destroy();
	window._fleurones.startGame();
}

document.addEventListener("DOMContentLoaded", async () => {
	await loadLocale();

	if (document.documentElement.classList.contains("desktop")) {
		loadDesktop();
	} else if (window.isPortrait()) {
		loadMobile();
	}
});

window.addEventListener("resize", async () => {
	if (document.documentElement.classList.contains("desktop")) {
		return;
	}
	document.documentElement.classList.toggle("portrait", window.isPortrait());
	document.documentElement.classList.toggle("landscape", !window.isPortrait());

	if (!window.initialized && window.isPortrait()) {
		loadMobile();
	}
});
