export async function loadLocale() {
	const { default: locale } = await import(
		`../locales/${document.documentElement.lang}.json`
	);
	window._locale = locale;
	document.querySelectorAll("[data-l10n]").forEach(
		/** @param {HTMLElement} el */
		(el) => {
			const [section, key] = el.getAttribute("data-l10n").split(".");
			const text = window._locale[section][key];
			if (!text) {
				return;
			}

			switch (el.tagName.toLowerCase()) {
				case "svg":
					el.title = window._locale[section][key];
					break;

				case "img":
					// @ts-ignore
					el.alt = window._locale[section][key];
					break;

				default:
					el.innerHTML = window._locale[section][key];
					break;
			}
		},
	);
}
